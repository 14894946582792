import React,{useState} from "react";
import MentalHealthCard from "./whatpage-card";
import i18n from "i18n-js";
const ProductsServices = () => {
const [align,setAlign]=useState(true)
  return (
    <section id="mhh-card-bg-img">
      <MentalHealthCard mhtext1={i18n.t("products-services-text1")} mhtext2={i18n.t("products-services-text2")} mhtext3={i18n.t("products-services-text3")} classred="what-card-padding" heading={i18n.t("products-heading")} textclass={align}/>
    </section>
  );
};
export default ProductsServices;
