import React from "react";
import Layout from "../../components/layout";
import HeaderOne from "../../components/header/header-one";
import StickyHeader from "../../components/header/sticky-header";
import Footer from "../../components/footer";
import ProductsServicesc from "../../components/what/products-servicesc";
import ComingSoon from "./Coming-soon";
const Products = () => {
  return (
    <Layout pageTitle="Products & Services">
      <HeaderOne />
      <StickyHeader />
      <section className="mhh-bg-image">
      <ProductsServicesc />
      <ComingSoon />
      </section>
      <Footer />
    </Layout>
  );
};
export default Products